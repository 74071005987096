/* eslint-disable @typescript-eslint/camelcase */
import {
  DeliveryVendor,
  VendorData,
  VendorEntities
} from "@/domain/entities/Vendor";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { Pagination } from "@/domain/entities/Pagination";
import { container } from "tsyringe";
import { VendorPresenter } from "../presenters/VendorPresenter";
import {
  AddDeliveryVendorApiRequest,
  EditVendorApiRequest
} from "@/data/payload/api/VendorApiRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";

export interface VendorState {
  isLoading: boolean;
  isLoadingDetail: boolean;
  vendorData: VendorEntities;
  vendorDetail: VendorData;
  isError: boolean;
  isErrorEdit: boolean;
  isRoute: boolean;
  convertContractStart: Date;
  convertContractEnd: Date;
  openModal: boolean;
  errorCause: string;
  messageSuccess: string;
}
@Module({ namespaced: true, dynamic: true, store, name: "vendor" })
class VendorStore extends VuexModule implements VendorState {
  public convertContractStart = new Date();
  public convertContractEnd = new Date(0, 0, 0);
  public isLoading = false;
  public isLoadingDetail = false;
  public vendorData = new VendorEntities(new Pagination(1, 10), []);
  public vendorDetail = new VendorData();
  public search = "";
  public status = "";
  public page = 1;
  public limit = 10;
  public isError = false;
  public isErrorEdit = false;
  public isRoute = false;
  public openModal = false;
  public openModalSuccess = false;
  public errorCause = "";
  public messageSuccess = "";
  public firstRequest = false;

  @Action
  public getVendorList(params: {
    search: string;
    status: string;
    page: number;
    limit: number;
  }) {
    this.setLoading(true);
    const presenter = container.resolve(VendorPresenter);
    return presenter
      .getVendorList(params.search, params.status, params.page, params.limit)
      .then((res: VendorEntities) => {
        this.setVendorList(res);
        this.setError(false);
        this.setErrorCause("");
        return res;
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
        return new VendorEntities(new Pagination(1, 10), []);
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public getVendorDetail(params: { id: any }) {
    this.setLoadingDetail(true);
    const presenter = container.resolve(VendorPresenter);
    presenter
      .getVendorDetail(params.id)
      .then((res: VendorData) => {
        this.setVendorDetail(res);
        this.setContractStart(new Date(this.vendorDetail.contract_start));
        this.setContractEnd(new Date(this.vendorDetail.contract_end));
        this.setRoute(res.status.toLowerCase() === "active");
        this.setError(false);
        this.setErrorEdit(false);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoadingDetail(false);
      });
  }

  @Action
  public editVendor(params: {
    vendor_id: number;
    address: string;
    email: string;
    status: string;
    phone_number: string;
    tax_number: string;
    contract_start: string;
    contract_end: string;
  }) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(VendorPresenter);
    presenter
      .editVendor(
        new EditVendorApiRequest(
          params.vendor_id,
          params.address,
          params.email,
          params.status,
          params.phone_number,
          params.tax_number,
          params.contract_start,
          params.contract_end
        )
      )
      .then(() => {
        this.setMessageSuccess(
          `Detail Vendor "${this.vendorDetail.name}" berhasil diubah`
        );
        this.setOpenModalSuccess(true);
        this.setErrorEdit(false);
        this.setErrorCause("");
      })
      .catch((err: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Perubahan Vendor Gagal!", () =>
            this.editVendor(params)
          )
        );
      })
      .finally(() => {
        this.setModalOpenSave(false);
        MainAppController.closeLoading();
      });
  }

  @Action
  public async setNextPage() {
    await this.nextPage();
    this.getVendorList({
      search: this.search,
      status: this.status,
      page: this.page,
      limit: 10
    });
  }

  @Action
  public async setPrevPage() {
    if (this.page !== 1) {
      await this.prevPage();
      this.getVendorList({
        search: this.search,
        status: this.status,
        page: this.page,
        limit: 10
      });
    }
  }

  @Action
  public setFirstPage() {
    this.setFirst();
  }

  @Action
  public setPageAction(value: number) {
    this.setPage(value);
    this.getVendorList({
      search: this.search,
      status: this.status,
      page: value,
      limit: 10
    });
  }

  @Action
  public selectStatusAction(value: string) {
    this.setStatus(value);
    this.getVendorList({
      search: this.search,
      status: this.status,
      page: 1,
      limit: 10
    });
  }

  @Action
  public searchAction(value: string) {
    this.setSearch(value);
    this.getVendorList({
      search: value,
      status: this.status,
      page: 1,
      limit: 10
    });
  }

  @Action
  public clear() {
    this.searchAction("");
    this.getVendorList({
      search: "",
      status: this.status,
      page: 1,
      limit: 10
    });
  }

  @Action
  public handleError() {
    this.setErrorEdit(false);
  }

  @Mutation
  public setModalOpenSave(bool: boolean) {
    this.openModal = bool;
  }

  @Mutation
  public setMessageSuccess(str: string) {
    this.messageSuccess = str;
  }

  @Mutation
  public setContractStart(date: Date) {
    this.convertContractStart = new Date(date);
  }
  @Mutation
  public setContractEnd(date: Date) {
    this.convertContractEnd = new Date(date);
  }

  @Mutation
  private setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @Mutation
  private setLoadingDetail(isLoading: boolean) {
    this.isLoadingDetail = isLoading;
  }

  @Mutation
  private setVendorList(data: VendorEntities) {
    this.vendorData = data;
  }

  @Mutation
  private setVendorDetail(data: VendorData) {
    this.vendorDetail = data;
  }

  @Mutation
  public setRoute(boolean: boolean) {
    this.isRoute = boolean;
  }

  @Mutation
  private async setPage(page: number) {
    this.page = page;
  }

  @Mutation
  private async nextPage() {
    if (this.page >= 1) {
      this.page = Number(this.page) + 1;
    }
  }

  @Mutation
  private async prevPage() {
    this.page = Number(this.page) - 1;
  }

  @Mutation
  private async setFirst() {
    this.page = 1;
  }

  @Mutation
  private async setSearch(value: string) {
    this.search = value;
  }

  @Mutation
  public async setStatus(value: string) {
    this.status = value;
  }

  @Mutation
  private setError(boolean: boolean) {
    this.isError = boolean;
  }

  @Mutation
  private setErrorEdit(bool: boolean) {
    this.isErrorEdit = bool;
  }

  @Mutation
  private setErrorCause(str: string) {
    this.errorCause = str;
  }

  @Mutation
  public setOpenModalSuccess(bool: boolean) {
    this.openModalSuccess = bool;
  }

  @Mutation
  public setFirstRequest(val: boolean) {
    this.firstRequest = val;
  }

  @Action
  public initRequest() {
    this.setFirstRequest(true);
    this.getVendorList({
      search: "",
      status: "",
      page: 1,
      limit: 10
    });
  }

  @Action
  public fetchVendorList() {
    this.setFirstRequest(false);
    this.getVendorList({
      search: "",
      status: "",
      page: 1,
      limit: 10
    });
  }

  @Action
  public async getDeliveryVendorList(params: {
    page: number;
    limit: number;
    search: string;
  }) {
    const presenter = container.resolve(VendorPresenter);
    return presenter
      .getDeliveryVendorList(params.page, params.limit, params.search)
      .then((res: DeliveryVendor[]) => {
        return res;
      })
      .catch(() => {
        return [];
      });
  }

  @Action
  public async addDeliveryVendor(params: { deliveryVendorName: string }) {
    const presenter = container.resolve(VendorPresenter);
    return presenter
      .addDeliveryVendor(
        new AddDeliveryVendorApiRequest(params.deliveryVendorName)
      )
      .then((res: ResponsePayload) => {
        return res;
      })
      .catch((err: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Tambah Vendor Gagal !", () => {
            this.addDeliveryVendor(params);
          })
        );
        return new ResponsePayload();
      });
  }
}

export const VendorController = getModule(VendorStore);

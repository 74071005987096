/* eslint-disable @typescript-eslint/camelcase */
import {
  EditVendorRequestInterface,
  DeliveryVendorRequestInterface
} from "@/data/payload/contracts/VendorRequest";

export class EditVendorApiRequest implements EditVendorRequestInterface {
  private vendor_id: number;
  private address: string;
  private email: string;
  private status: string;
  private phone_number: string;
  private tax_number: string;
  private contract_start: string;
  private contract_end: string;

  constructor(
    vendor_id: number,
    address: string,
    email: string,
    status: string,
    phone_number: string,
    tax_number: string,
    contract_start: string,
    contract_end: string
  ) {
    this.vendor_id = vendor_id;
    this.address = address;
    this.email = email;
    this.status = status;
    this.phone_number = phone_number;
    this.tax_number = tax_number;
    this.contract_start = contract_start;
    this.contract_end = contract_end;
  }

  public toJSON(): string {
    return JSON.stringify({
      vendor_id: this.vendor_id,
      address: this.address,
      email: this.email,
      status: this.status,
      phone_number: this.phone_number,
      tax_number: this.tax_number,
      contract_start: this.contract_start,
      contract_end: this.contract_end
    });
  }
}

export class AddDeliveryVendorApiRequest
  implements DeliveryVendorRequestInterface {
  deliveryVendorName: string;

  constructor(deliveryVendorName: string) {
    this.deliveryVendorName = deliveryVendorName;
  }

  public toJSON(): string {
    return JSON.stringify({
      vendor_delivery_name: this.deliveryVendorName
    });
  }
}
